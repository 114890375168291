<template>
  <div class="lands" v-show="showId === '地块'">
    <img
      class="col-img"
      :src="
        plotForm.photo
          ? uploadImgs + plotForm.photo
          : require('../assets/image/land/bgc.png')
      "
    />
    <p class="col-title one-txt-cut">
      <span>{{ plotForm.title ? plotForm.title : "" }}</span>
      &nbsp;
      <span>{{
        plotForm.product_list[0] ? plotForm.product_list[0].title : ""
      }}</span>
    </p>
    <img
      class="map-lands-icon"
      src="@/assets/image/map_lands_icon.png"
      alt=""
    />
    <div class="col-txt">
      <span>种植作物：</span>
      <span
        class="text-val one-txt-cut"
        v-for="(t, i) in plotForm.product_list"
        :key="i"
        >{{ t.title }}
      </span>
    </div>
    <div class="col-txt">
      <span>占地面积：</span>
      <span class="text-val one-txt-cut">{{ plotForm.area }}亩</span>
    </div>
    <div class="col-txt">
      <span>种植主体：</span>
      <span class="text-val one-txt-cut">{{ plotForm.desc }}</span>
    </div>
    <div class="col-txt">
      <span>已落实订单企业：</span>
      <span class="text-val one-txt-cut">{{
        plotForm.company_name ? plotForm.company_name : "无"
      }}</span>
    </div>
    <div class="col-txt">
      <span>联系电话：</span>
      <span class="text-val one-txt-cut">{{
        plotForm.phone ? plotForm.phone : "无"
      }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    showId: {
      type: String,
      default: "",
    },
    plot_id: {
      type: [Number, String],
      default: 0,
    },
    plotForm: {
      type: Object,
      default: () => {
        return {
          company_name: "",
          phone: "",
          area: "",
          photo: "",
          title: "",
          desc: "",
          product_list: [],
        };
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
      farmInfo: (state) => state.user.farmInfo,
    }),
  },
};
</script>

<style lang="less" scoped>
.lands {
  width: 282px;
  height: 380px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top: 6px solid #125397;
  background-color: #0f243d;
  display: flex;
  padding: 25px 33px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 80px;
  left: 78px;
  z-index: 9;
  transition: all 0.5s;

  .col-img {
    width: 158px;
    height: 105px;
    margin-bottom: 12px;
  }

  .col-title {
    width: 100%;
    color: #fff;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  .map-lands-icon {
    width: 219px;
    margin-bottom: 15px;
    height: 9px;
  }
  .col-txt {
    width: 100%;
    color: #fff;
    font-size: 14px;
    margin-bottom: 8px;
    span {
      display: inline-block;
      width: 42.5%;

      &:first-of-type {
        width: 57.5%;
      }
    }
    .text-val {
      color: #409eff;
    }
  }
}
</style>
